<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import RecordsDialog from "./components/records.vue"
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { useStockApi } from "@/apis/stock";
import { useSystemApi } from "@/apis/system";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '出入库记录',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    RecordsDialog,
    VueBootstrapTypeahead,
    flatPickr
    
  },
  data() {
    return {
      tableData: [],
      model_data : [],
      storageList :[],
      title: '出入库记录',
      items: [
        {
          text: this.$t('stock.list.nav_first_item'),
          href: "/",
        },
        {
          text: '出入库记录',
          active: true,
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 30,

      fields: [
      
        {
          key: "stock_model",
          label:'型号',
          sortable: true,
        },

        {
          key: "model_name",
          label:'名称',
          sortable: true,
        },

        {
          key: "stock_count",
          label:'数量',
          sortable: true,
        },

        {
          key: "stock_type",
          label:'类型',
          sortable: true,
        },

        {
          key: "stock_date",
          label:'日期',
          sortable: true,
        },
       
        {
          key: "storage_tag",
          label: '库',
          sortable: true,
        },

        {
          key: "operator",
          label: '操作员',
          sortable: true,
        },
     
      ],

      p_model : '',
      currentStorage : '',
      dateStart : '',
      dateEnd   : ''
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },

   
  },
  mounted() {
    
    this.query_storage()
    this.query_stock_record()

  },
  methods: {

    query_stock_record() {
      let data = {
        page : {
          page_no   : this.currentPage,
          page_size : this.perPage
        }
      }

      if (this.p_model != '') {
        data.model = this.p_model
        data.page.page_no = 1
      } 
      
      if (this.currentStorage != '') {
        data.storage = this.currentStorage
      }

      if (this.dateStart != '') {
        data.date_start  = this.dateStart
      }

      if (this.dateEnd != '') {
        data.date_end  = this.dateEnd
      }
     
      useStockApi().query_stock_record_detail_query(data).then(res =>{
        if (res.err == 0) {
          this.tableData = []
          res.data.map(e => {
            this.tableData.push(e)
          })

          this.totalRows = res.page.total_count
        }
      })
    },

    query_storage() {
      useSystemApi().list_system_conf('STORAGE').then(res => {
        if (res.err == 0) {
          this.storageList = [
            {
              value : '',
              label :  '全部',
              selected : true
            }
          ]

          res.data.map(e => {
           
            this.storageList.push({
              code    : e.key_field,
              name    : e.value_field,
              remarks : e.remarks,
              id      : e.id,
              value   : e.key_field,
              label   : e.value_field
            })
          })
        }

        new Choices('#storage_list', {choices : this.storageList})
      })
    },


    show_records_dialog() {
      this.$bvModal.show('records_dialog')
    },

    search_model(searched_str) {
      useSystemApi().model_search('MODEL', searched_str).then(res => {
        if (res.err == 0) {
          this.model_data = []
          res.data.map(e => {
            this.model_data.push(e.key_field)
          })
        }
      
      })
    },

   

    pageChange(p) {
      this.currentPage = p
      this.query_stock_list()
    },
    

  },

  watch: {
    p_model: function(new_val) {
      this.search_model(new_val)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal
     id="records_dialog"
      centered
      title="Add New Event"
      title-class="text-black font-18"
      body-class="p-3"
      size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc
    >
      <RecordsDialog></RecordsDialog>
    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md-2">

                <div class="row">
                  <div class="col-md-3">
                    <label class="d-inline-flex align-items-center"> 型号选择&nbsp;</label>
                  </div>
                  <div class="col-md-8">
                    <vue-bootstrap-typeahead v-model="p_model" :data="model_data" autocomplete="off" :inputClass="{}" />
                  </div>
                 
                </div>

               
              </div>
              <div class="col-sm-8 col-md-4">
                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 日期&nbsp;</label>
                  </div>
                  <div class="col-md-4">
                    <flat-pickr placeholder="Select a date" class="form-control" v-model="dateStart"></flat-pickr>
                  </div>
                  <div class="col-md-2"><input type="checkbox" class="form-check" >
                    <label class="d-inline-flex align-items-center"> 区间选择&nbsp;</label>
                  </div>
                  <div class="col-md-4">
                    <flat-pickr placeholder="Select a date" class="form-control" v-model="dateEnd"></flat-pickr>
                  </div>
                   
              
                </div>
              </div>

              <div class="col-sm-6 col-md-3">
                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 仓库过滤&nbsp;</label>
                  </div>
                  <div class="col-md-6">
                    <select
                      class="form-control"
                      id="storage_list"
                      v-model="currentStorage"
                    >
                  </select>
                  </div>
                 
                </div>
               
              
              </div>

              <!-- Search -->
              <div class="col-sm-6 col-md-3">
                <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
                 <b-button variant="primary" @click="query_stock_record">查询</b-button>
                 <b-button variant="secondary" @click="$router.push({'name': 'stock-list'})">返回</b-button>
                </div>
              </div>
              <!-- End search -->
            </div>


            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table class="datatables" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage"  >
                <template #cell(stock_type)="data">
                    {{ data.item.stock_record.stock_type == 'IN' ?'入库': (data.item.stock_record.stock_type == 'OUT'? '出库' :'废弃') }}
                </template>

                <template #cell(stock_date)="data">
                    {{ data.item.stock_record.stock_date}}
                </template>

                <template #cell(operator)="data">
                    {{ data.item.stock_record.operator}}
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"  @change="pageChange" ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
