
const tableData = [
    {
        issue_number : '#12523',
        status : '等待检验',
        date : '2023-02-01',
        manager : '李宏伟',
        number  : 20,
        level : '轻微',
        type : '脱空',
    },

    {
        issue_number : '#125224',
        status : '等待检验',
        date : '2022-03-11',
        manager : '李宏伟',
        level : '轻微',
        number  : 18,
        type : '脱空',
    },
    {
        issue_number : '#12122',
        status : '等待检验',
        date : '2023-12-01',
        manager : '刘利超',
        level : '轻微',
        number  : 16,
        type : '脱空',
    },
    {
        issue_number : '#13509',
        status : '等待检验',
        date : '2023-08-04',
        manager : '张丽娜',
        level : '轻微',
        number  : 32,
        type : '井盖',
    },
    {
        issue_number : '#15523',
        status : '不合格',
        date : '2023-02-12',
        manager : '李宏伟',
        level : '轻微',
        number  : 1,
        type : '脱空',
    },
    {
        issue_number : '#18523',
        status : '合格',
        date : '2023-06-04',
        manager : '李宏伟',
        level : '轻微',
        number  : 10,
        type : '井盖',
    },
    {
        issue_number : '#12523',
        status : '检测处理',
        date : '2022-08-13',
        manager : '张立伟',
        level : '轻微',
        number  : 6,
        type : '脱空',
    },
    {
        issue_number : '#11523',
        status : '等待检验',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        number  : 9,
        type : '脱空',
    },
    {
        issue_number : '#52523',
        status : '等待入库',
        date : '2023-02-01',
        manager : '张丽娜',
        level : '轻微',
        number  : 12,
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '等待入库',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        number  : 11,
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '等待检验',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已入库',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已入库',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已入库',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '张丽娜',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已入库',
        date : '2023-02-01',
        manager : '张丽娜',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '张丽娜',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '张丽娜',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已修复',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    {
        issue_number : '#12523',
        status : '已入库',
        date : '2023-02-01',
        manager : '李宏伟',
        level : '轻微',
        type : '脱空',
    },
    
]

export { tableData };