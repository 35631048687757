<script>

import appConfig from "@/app.config";



import { tableData } from "../test_issue_data";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '库存清单',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    
    
    
  },
  data() {
    return {
      tableData: tableData,
      title: this.$t('stock.list.sub_title'),
      items: [
        {
          text: this.$t('stock.list.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('stock.list.nav_second_item'),
          active: true,
        },
      ],
      issue_url: '',
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
      
        {
          key: "type",
          label: this.$t('stock.list.table_fields.p_model'),
          sortable: true,
        },

        {
          key: "number",
          label: this.$t('stock.list.table_fields.p_count'),
          sortable: true,
        },
       
        {
          key: "manager",
          label: this.$t('stock.list.table_fields.p_manager'),
          sortable: true,
        },
       

        {
          key: "Pos",
          label: this.$t('stock.list.table_fields.action'),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

   
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;


  },
  methods: {


  },
};
</script>

<template>


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">


            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table class="datatables" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template #cell(Pos)>
                  <b-dropdown variant="white" toggle-class="p-0"> 
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                    </template>
                    <li>
                      <b-dropdown-item><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{ $t('stock.list.process.ship') }} </b-dropdown-item>
                    </li>
                    <li>
                      <b-dropdown-item><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{ $t('stock.list.process.check') }} </b-dropdown-item>
                    </li>
                  
                  </b-dropdown>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>
